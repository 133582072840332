<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">アプリケーション一覧</h3>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
            <label>キーワード</label>
            <el-input v-model="form.booking_id"></el-input>
        </div>

        <div class="col-md-3">
            <label>決済期間</label><br/>
            <el-date-picker
                v-model="form.time"
                type="datetimerange"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                :default-time="['12:00:00']"
                value-format="yyyy-MM-dd HH:mm:ss"
                >
            </el-date-picker>
        </div>

        <div class="col-md-2">
          <el-row style="margin-top:28px; margin-left:20px">
            <el-button size="medium" @click="clickSearch()" type="primary">検索</el-button>
          </el-row>
        </div>
      </div>
      <hr />
      <div class="d-flex flex-row justify-content-between align-items-center">
        <span class="demonstration">
          <my-pagination-top style="padding-left:8px;  padding-bottom:10px"
                              :total-record="parseInt(data.total)"
                              :current-page="parseInt(queryParam.page)"
                              :page-size-default="parseInt(data.per_page)"
                              @handleSizeChange="handleSizeChange"
          />
        </span>
      </div>
      <el-table
        :fit="true"
        border
        :data="data.data"
        style="width: 100%"
        :row-class-name="tableRowClassName">

        <el-table-column
          prop="id"
          label="予約番号"
          width="180">
        </el-table-column>

        <el-table-column
          prop="order.customer.name"
          label="お名前">
        </el-table-column>

        <el-table-column
          prop="order.customer.phone"
          label="電話番号">
        </el-table-column>

        <el-table-column
          prop="order.customer.email"
          label="メールアドレス">
        </el-table-column>

        <el-table-column
          prop="amount"
          label="決済金額">
        </el-table-column>

        <el-table-column
          prop="created_at"
          label="決済日"
          width="180"
          >
        </el-table-column>

        <el-table-column
          prop="card_number"
          label="請求書番号">
        </el-table-column>

      </el-table>
      <div v-if="data" class="row float-right pr-5 pt-7">
        <my-pagination
          :total-record="parseInt(data.total)"
          :currentPage="parseInt(data.current_page)"
          :page-size-default="parseInt(data.per_page)"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { LIST_TRANSACTION } from "@/core/services/store/transaction.module";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ListTransaction",
  data() {
    return {
      data: {},
      loading: false,
      querySearch: {
        page: "",
      },
      search: "",
      form: {
        time: ''
      }
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    queryParam() {
      return this.$route.query;
    },
  },

  watch: {
    queryParam() {
      this.getList();
    },
  },

  async created() {
    this.form.booking_id = this.$route.query.booking_id ?? '';
    this.form.time = this.$route.query.time ?? '';
    await this.getList();
  },

  methods: {
    async getList() {
        this.loading = true;
        let params = this.serialize(this.queryParam);
        let data = await this.$store.dispatch(LIST_TRANSACTION, params);
        if (data.code == SUCCESS) {
            this.data = data.data
        }
        this.loading = false;
    },

    handleSizeChange(val) {
      this.$router.push({
        name: "transaction",
        query: { ...this.queryParam, ...{ size: val, page: 1 } },
      });
    },

    handleCurrentChange(val) {
      this.$router.push({
        name: "transaction",
        query: { ...this.queryParam, ...{ page: val } },
      });
    },

    clickSearch()
    {
      this.$router.push({
          name: "transaction",
          query: {...this.queryParam, ...this.form, ...{page: 1}}
      }).catch(err => {
        this.getList();
      });
    },

    getOverallIndex(index) {
      return (this.data.current_page * this.data.per_page) - this.data.per_page + (index + 1)
    }
  }
  
};
</script>